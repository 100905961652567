import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'distribution',
    loadChildren: () => import('./pages/distribution/distribution.module').then( m => m.DistributionPageModule)
  },
  {
    path: 'distributionlist',
    loadChildren: () => import('./pages/distributionlist/distributionlist.module').then( m => m.DistributionlistPageModule)
  },
  {
    path: 'customize-card',
    loadChildren: () => import('./pages/customize-card/customize-card.module').then( m => m.CustomizeCardPageModule)
  },
  {
    path: 'receipt',
    loadChildren: () => import('./pages/receipt/receipt.module').then( m => m.ReceiptPageModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./pages/payment/payment.module').then( m => m.PaymentPageModule)
  },
  {
    path: ':storeslug',
    loadChildren: () => import('./pages/customize-card/customize-card.module').then( m => m.CustomizeCardPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./pages/customize-card/customize-card.module').then( m => m.CustomizeCardPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
